.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  transform: translateY(-64px);
  margin: auto;
}

.contact-box {
  max-width: 1512px;
  width: 100%;
  margin: auto;
  min-height: calc(100vh - var(--header-height) - 96px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2em 2em 0;
}

.icon-container {
  display: flex;
  margin: 1em 0;
  gap: 3em;
}

.contact-link {
  transition: fill 300ms ease-in-out, transform 300ms ease-in-out;
  fill: var(--primary-color);
}

.contact-link:hover {
  cursor: pointer;
  fill: var(--hover-background-color-secondary);
  transform: scale(1.02);
}

.sub-title-contact {
  font-size: 2rem;
}

@media (max-width: 1512px) {
  .contact-box {
    padding: 0;
  }
  .sub-title-contact {
    margin-left: 1em;
  }
}

@media (max-height: 440px) {
  .contact-container {
    transform: translateY(0);
  }
}