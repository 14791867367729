header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--fixed-primary-color);
  padding: 2em;
  z-index: 1;
  max-width: 1512px;
  margin: auto;
}

.name-container {
  text-decoration: none;

  .title-name {
    font-size: 1.5rem;
    color: var(--fixed-secondary-color);
  }
  
  .name-tag {
    font-weight: 100;
    font-size: 1.125rem;
    color: var(--fixed-secondary-color);
  }
}
.option-container {
  position: relative;
  display: flex;
  z-index: 3;
  align-items: center;
  gap: .5rem;
}

.theme-mode {
  * {
    color: #f6f6f6;
  }
}

.menu-btn {
  --button-color: #f6f6f6;
  border-radius: 8px;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  z-index: 3;
  font-size: .8rem;
  height: fit-content;
  width: fit-content;
  transition: all 500ms ease-in-out;
  min-height: 38px;

  &.undefined :is(.top, .bottom) {
    animation: none;
  }

  &.closed :is(.top, .bottom) {
    animation: to-open-icon 700ms forwards;
  }

  &.opened :is(.top, .bottom) {
    animation: to-close-icon 700ms forwards;
  }

  .hamburger {
    transition: rotate 500ms 100ms;
  }

  .hamburger:hover {
    transform: scale(1.02);
  }

  &.opened .hamburger {
    rotate: 1turn;
  }

  .line.top {
    --rotation: -45deg;
    transform-origin: 75px 29px;
  }

  .line.middle {
    transition: 1s;
  }
  
  &[aria-expanded='true'] .line.middle {
    opacity: 0;
  }

  .line.bottom {
    --rotation: 45deg;
    transform-origin: 75px 53px;
  }
}

.sidebar {
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: var(--primary-background-color);
  transition: all 500ms ease-in-out;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  z-index: 2;
  
  .nav-link-container {    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50vh;
    margin: 0 4rem;

  }

  @supports (backdrop-filter: blur(1rem)) {
    background-color: var(--primary-background-color-trans);
    backdrop-filter: blur(1rem);
  }

  .menu-link {
    transition: all 900ms ease-in-out;
    opacity: 0;
    text-decoration: none;
    font-size: 1.4rem;
    position: relative;
    display: block;
  }

  .menu-link:visited,
  .menu-link:active {
    color: var(--primary-color);
    text-decoration: none;
  }

  .menu-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.15em;
    background-color: var(--primary-color);
    transition: opacity 300ms, transform 300ms;
    transform: scale(0);
    transform-origin: center;
    text-decoration: none;
  }
  
  .menu-link:hover::after,
  .menu-link:focus::after{
    transform: scale(1);
  }
}

.close-btn {
  transition: opacity 0.9s ease;
}

.menu-btn[aria-expanded='false'] {
  .close-btn {
    opacity: 0;
  }
}

.menu-btn[aria-expanded='true'] {
  --button-color: var(--primary-color);

  .close-btn {
    opacity: 1;
  }  
}

.sidebar[aria-expanded='true'] {
  translate: 0;
  opacity: 1;

  .menu-link {
    opacity: 1;
    translate: -30px;
    width: fit-content;
  }
}

.sidebar[aria-expanded='false'] {
  translate: 100%;

  .menu-link {
    visibility: hidden;
    &:nth-child(1) {
      translate: 15px;
    }

    &:nth-child(2) {
      translate: 30px;
    }

    &:nth-child(3) {
      translate: 45px;
    }

    &:nth-child(4) {
      translate: 60px;
    }

    &:nth-child(5) {
      translate: 75px;
    }
  }
}

@keyframes to-close-icon {
  0% {
    stroke-dashoffset: 0;
    
  }
  40% {
    stroke-dashoffset: 79.9;
  }
  60% {
    stroke-dashoffset: 79.9;
    rotate: var(--rotation);
  }
  100% {
    stroke-dashoffset: 0;
    rotate: var(--rotation);
  }
}

@keyframes to-open-icon {
  0% {
    stroke-dashoffset: 0;
    rotate: var(--rotation);
  }
  40% {
    stroke-dashoffset: 79.9;
    rotate: var(--rotation);
  }
  60% {
    stroke-dashoffset: 79.9;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 1512px) {
  .sidebar {
    position: fixed;
    right: 0;
    height: 100vh; 
  }
}

@media (min-width: 1513px) {
  .sidebar {
    position: absolute;
  }
}

@media (max-width: 810px){
  header {
    padding: 1em;
  }
}

@media (min-width: 650px) {
  .name-container {
    display: flex;

    .name-tag {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 374px) {
  .name-container {
    h1 {
      font-size: 1.4rem;
    }
  }
}

@media (max-width: 365px) {
  .name-container {
    .title-name {
      font-size: 1.25rem;
    }
    
    .name-tag {
      font-size: .95rem;
    }
  }
}