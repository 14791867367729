.portfolio-box {
  max-width: 1512px;
  margin: auto;
  min-height: 100vh;
  padding: 2em;


  .sub-title-project {
    font-size: 2rem;
    opacity: 0;
  }
}

.portfolio-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(302px, max-content));
  grid-gap: 1.15em;
  margin: 2em 0;
  justify-content: center;
}

@media (max-width: 480px) {
  .portfolio-container {
    grid-template-columns: repeat(auto-fit, minmax(230px, max-content));
  }
}

@media (max-width: 554px), (max-height: 400px) {
  .portfolio-container {
    grid-template-columns: repeat(auto-fill, 1fr);
  }
  
  .portfolio-box {
    min-height: 100vh;
    padding: 2em 0 0;

    .sub-title-project {
      padding: 0 0.5em;
    }
  }
}

@media (orientation: landscape) {
  .portfolio-box {
    padding: 0 2em;

    .sub-title-project {
      padding: 0;
    }
  }
}

@media (orientation: landscape) and (max-height: 420px) {
  .portfolio-container {
    grid-template-columns: auto;
  }
}