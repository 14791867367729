.form-box {
  max-width: 1512px;
  width: 100%;
  margin: auto;
  min-height: calc(100vh - var(--header-height) - 66px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2em 2em 0;
  position: relative;
}

.sub-title-form {
  font-size: 2rem;
}

.form-item {
  display: flex;
  flex-direction: column;
}


.form-label {
  font-weight: 600;
  padding: 10px 0;
  font-size: 1.1rem;
}

.form-input {
  -webkit-appearance: none;
  width: 100%;
  max-width: 425px;
  padding: 0.4em 0.8em;
  font-size: 1rem;
  color: black;
  outline: none;
  border:  2px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
  transition: background 0.25s, border-color 0.25s, color 0.25s;
}

.form-input:focus {
  background: #fff;
  border-color: #1b5ac7;
  border-width: 2px;
}

.form-input::placeholder {
  color: #a2a2a2;
}

.form-input:invalid[data-focused="true"] {
  color: #d50000;
  background: #fff8f8;
  border-color: #d50000;
}

.form-input:invalid[data-focused="true"]::placeholder {
  color: #fe8686;
}

.form-input + .form-error {
  padding: 0.4rem 0;
  font-size: 0.9rem;
  color: #d50000;
  visibility: hidden;
}

.form-input:invalid[data-focused="true"] + .form-error {
  visibility: visible;
}

textarea.form-input {
  min-height: 200px;
}

.form-button {
  margin: 0.65em 0 1.1em;
  padding: 0.3em 0.6em;
  border:  2px solid var(--hover-background-color);
  border-radius: 4px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.form-button:hover {
  background-color: var(--hover-background-color);
}

@media (max-width: 810px) {
  .form-box {
    padding: 2em 1em 0;
  }
}