.about-container {
  max-width: 1512px;
  margin: auto;
  min-height: calc(100vh - var(--header-height) - 32px);
  padding: 2em 2em 0 2em;
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;

  .about-content {
    transform: translateY(-32px);
  }

  h2 {
    font-size: 3.75rem;
    margin-bottom: .5em;
  }

  p {
    font-size: 1.3rem;
    max-width: 920px;
  }
}

.profile-img-container {
  width: 100%;
  max-width: 520px;
  min-width: 375px;
  height: auto;
  max-height: 600px;
}

.profile-img {
  height: 600px;
  filter: grayscale(1);
  object-fit: cover;
}

@media (max-width: 1000px) {
  .about-container {
    h2 {
      font-size: 2.75rem;
    }
  
    p {
      font-size: 1.1rem;
    }

    article {
      margin: 2em;
    }
  }
}

@media (max-width: 810px), (max-height: 420px) and (max-width: 810px) {
  .about-container {
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin-top: 1.5rem;
  }

  .about-content {
    padding: 1em;
  }

  .profile-img-container {
    width: 100%;
    max-width: inherit;
    min-width: fit-content;
  }
}

@media (max-height: 420px) and (min-width: 374px) {
  .profile-img {
    max-height: fit-content;
    height: 10%;
  }
}

@media (max-width: 374px) {
  .about-container {
    h2 {
      font-size: 2rem;
    }
  }
}