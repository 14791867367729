.project-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--secondary-color);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  opacity: 0;
  max-width: 463px;
  overflow: hidden;
  text-decoration: none;

  &:hover,
  &:focus-within {
    .project-photo {
      scale: 1.01;
      filter: grayscale(0);
    }
  }

}

.project-photo {
  width: 100%;  
  object-fit: cover;
  object-position: center;
  transition: 300ms linear;
  filter: grayscale(1);
}

.link-container:focus {
  .project-container .project-photo {
    scale: 1.01;
    filter: grayscale(0);
  }
}

.info-box {
  padding: 1.1em;
  display: grid;
  grid-template-rows: max-content auto;
  flex-grow: 1;

  .project-name {
    font-size: 1.3rem;
  }
}

.link-container {
  background-color: var(--primary-background-color);
  width: auto;
  padding: 0.3em 0.6em 0.3em 0.6em;
  margin: 1.25em 0;
  border-radius: 4px;
  display: flex;
  text-decoration: none;     
  transform: translateX(0);
  transition: all 150ms ease-in-out;

  .arrow {
    transition: transform 150ms ease-in-out;
    margin-left: .5em;
    position: absolute;
    right: 10px
  }
 
  &:visited {
    color: var(--primary-color);
  }
}

.link-container:hover, .link-container:focus {
  background-color: var(--hover-background-color);
  .arrow {
      transform: translateX(10px);
  }
}

.navigation-container {
  margin-top: .5em;
  max-width: 100%;
  align-self: flex-end;
}

@media (max-width: 554px) {
  .project-container {
    box-shadow: none;
    opacity: 0;
    max-width: none;  
  }
  
  .project-photo {
    width: 100%;  
    object-fit: cover;
    transition: 300ms linear;
    filter: grayscale(1);
  }
}

@media (orientation: landscape) and (max-height: 420px) {
  .project-container {
    min-width: 100%;
    max-width: fit-content;
  }

  .project-photo {
    max-height: 80vh;
  }
}

@media (pointer: fine) {
  .is-touch {
    display: none;
  }
}